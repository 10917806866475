html,
body {
  padding: 0;
  margin: 0;
  font-family:
    -apple-system,
    BlinkMacSystemFont,
    Segoe UI,
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    Fira Sans,
    Droid Sans,
    Helvetica Neue,
    sans-serif;
  background-color: white !important;
}

a {
  color: inherit;
  text-decoration: none !important;
}

* {
  box-sizing: border-box;
}

/* Theme */
.custom-scrollbars__content {
  overflow: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
:root {
  --primary-color: #a93bff;
  --secondary-color: #5932ea;
  --active-color: #d957f9;
  --bg-secondary: #edf2fa;
  --text-color: #39414a;
  --primary-gradient: linear-gradient(180deg, #a93bff 0%, #8834ff 100%);
  --light-color: #979ca2;
  --table-border: #d8e4f8;
}

button {
  border: none !important;
  border-radius: 8px !important;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.btn-primary {
  background: var(--primary-gradient) !important;
}

.btn-secondary {
  background-color: var(--secondary-color) !important;
}
.slick-slide {
  /* padding-right: 32px !important; */
}
.btn-outline-primary {
  background: transparent !important;
  border: 1px solid var(--active-color) !important;
  color: var(--active-color) !important;
  transition: all 0.2s linear;
}

.btn-outline-primary:hover {
  color: white !important;
  background: var(--active-color) !important;
  border-color: var(--active-color) !important;
  transition: all 0.2s linear !important;
}

.btn-outline-secondary {
  background: transparent !important;
  border: 1px solid var(--secondary-color) !important;
  color: var(--secondary-color) !important;
  transition: all 0.2s linear;
}

.btn-outline-secondary:hover {
  color: white !important;
  background: var(--secondary-color) !important;
  border-color: #664e96 !important;
  transition: all 0.2s linear !important;
}

.btn-light {
  background: var(--light-color) !important;
  color: #fff !important;
}

.btn-outline-light {
  background: transparent !important;
  color: var(--light-color) !important;
  border: 1px solid var(--light-color) !important;
  transition: all 0.2s linear;
}

.btn-outline-light:hover {
  background: var(--light-color) !important;
  color: #fff !important;
  transition: all 0.2s linear !important;
}

h1,
h3,
h4,
h5 h6,
strong {
  color: #39414a;
}

h1 {
  font-size: 44px;
}

p {
  color: #39414a;
}

.fw-500 {
  font-weight: 500;
}
.fw-400 {
  font-weight: 400;
}
.fw-700 {
  font-weight: 700;
}
.link {
  color: var(--active-color) !important;
}

input:focus,
textarea:focus {
  box-shadow: none !important;
  border-color: var(--active-color) !important;
}

select:focus {
  box-shadow: none !important;
  border-color: #000000 !important;
}

.cursor-pointer {
  cursor: pointer;
}
.fs-13 {
  font-size: 13px;
}
.fs-15 {
  font-size: 15px;
}
.fs-16 {
  font-size: 16px !important;
}
.fs-14 {
  font-size: 14px;
}

.fs-12 {
  font-size: 12px;
}

.fs-18 {
  font-size: 18px !important;
}
.fs-20 {
  font-size: 20px;
}

.form-control.is-valid:focus,
.was-validated :valid.form-control {
  border-color: inherit !important;
  background-image: inherit !important;
  box-shadow: inherit !important;
}
.tooltip > div.tooltip-inner {
  background-color: #fff !important;
  color: var(--text-color) !important;
  box-shadow: 0px 4px 10px rgba(101, 129, 148, 0.25);
  padding: 10px 20px;
  border-radius: 13px;
  max-width: 300px;
}

.tooltip.show {
  background-color: #fff !important;
  opacity: 1 !important;
}

.tooltip-arrow::before {
  border-top-color: #fff !important;
}

.text-success {
  color: rgba(25, 97, 30, 1) !important;
}

.text-danger {
  color: #f85e40 !important;
}

.breadcrumb-link {
  color: rgba(128, 128, 128, 1);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.reserveDetailPolicy {
  font-size: 15px;
  line-height: 130%;
}

@media screen and (min-width: 1501px) {
  .main {
    margin: 0px 297px;
  }
  .slider {
    position: relative;
    width: 200px !important;
  }
  .thumb {
    width: 200px !important;
  }
}

@media screen and (max-width: 1600px) {
  .main {
    margin: 0px 235px;
  }
  .slider {
    position: relative;
    width: 200px;
  }
  .thumb {
    width: 200px;
  }
}

@media screen and (max-width: 1440px) {
  .main {
    margin: 0px 120px;
  }
  .slider {
    position: relative;
    width: 200px;
  }
  .thumb {
    width: 200px;
  }
}

@media screen and (max-width: 993px) {
  .main {
    margin: 0px 0px;
  }
  h1 {
    font-size: 38px;
  }
}

@media screen and (max-width: 575px) {
  .slick-slide {
    padding-right: 0px !important;
  }
  .main {
    margin: 0px 0px;
  }
  /* p {
    font-size: 14px;
  }
  /* span {
    font-size: 14px;
  } */
  h1 {
    font-size: 30px;
  }
}

.btn-close:focus {
  box-shadow: none !important;
}

.dropdown-toggle::after {
  content: url(../../public/icons/chevrondown.svg) !important;
  border: none !important;
  vertical-align: 0.215em !important;
}
.notArrow .dropdown-toggle::after {
  content: "" !important;
  border: none !important;
  vertical-align: 0.215em !important;
}

/* .navbar-nav .dropdown-menu {
  box-shadow: 0px 0px 8px #0a141e21 !important;
  border: none !important;
} */
.notArrow .dropdown-menu.show {
  display: none !important;
}
.dropdown-menu.show {
  display: grid !important;
}

.dropdown-item:active {
  background-color: #f8f6ff !important;
}
.navbar-toggler {
  display: none;
}

#navbarToggleExternalContent {
  transform: translateX(-100%);
  transition: transform 0.35s ease;
  display: block;
  height: 100%;
  min-height: 100%;
  z-index: 2;
  width: 100%;
  background-color: white !important;
}

#navbarToggleExternalContent.menu-show {
  transform: translateX(0%);
}

.secondary-link {
  color: #5932ea;
}

.form-check-input:checked {
  background-color: #d957f9 !important;
  border: none !important;
}
/*  */
.slider {
  position: relative;
  width: 160px;
}

.slider__track,
.slider__range {
  border-radius: 3px;
  height: 5px;
  position: absolute;
}

.slider__track {
  background-color: #d957f9;
  width: 100%;
  z-index: 1;
  opacity: 0.2;
}

.slider__range {
  background-color: #5932ea;
  z-index: 2;
}

/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
}

.thumb {
  pointer-events: none;
  position: absolute;
  height: 0;
  width: 168px;
  outline: none;
}

.thumb--zindex-3 {
  z-index: 3;
}

.thumb--zindex-4 {
  z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
  background-color: #d957f9;
  border: 2px solid #5932ea;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
  background-color: #f1f5f7;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px #ced4da;
  cursor: pointer;
  height: 18px;
  width: 18px;
  margin-top: 4px;
  pointer-events: all;
  position: relative;
}
.irs--flat .irs-grid-pol {
  background-color: aqua;
}
.irs-grid-pol {
  position: absolute;
  top: 0;
  left: 0;
  width: 1px;
  height: 8px;
  background: #000;
}

.arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  margin-top: -25px;
}
.discount {
  font-size: 13px;
  text-decoration-line: line-through;
  opacity: 0.5;
  font-weight: 400;
}
.borderBottom {
  border-bottom: 1px solid #edf2fa;
}
.image_cover {
  object-fit: scale-down !important;
}

#nprogress .bar {
  background: var(--primary-color) !important;
}

#nprogress .spinner .spinner-icon {
  border-top-color: var(--primary-color);
  border-left-color: var(--primary-color);
}

.picon .dropdown-toggle::after {
  content: "" !important;
}
/* login */

.loginHeader {
  background-image: url(/_next/static/media/bannerBgxl.ee5b3d85.jpg) !important;
  background-size: contain;
  background-position: bottom;
  width: 100%;
  height: 100vh !important;
  background-repeat: no-repeat;
}
.loginBanner {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}
.tooltip.show {
  background-color: transparent !important;
}
.was-validated .form-control:invalid {
  border-color: #dc3545 !important;
}

.new {
  background: #000b58;
}

.used {
  background: #eca72c !important;
}

.refurbished {
  background: #17a2b8;
}

.slick-slide {
}
.confetti {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1000;
}
.confetti-piece {
  position: absolute;
  width: 10px;
  height: 20px;
  background: #ffd300;
  top: 0;
  opacity: 0;
}
.mySwiper {
  transform: translate3d(0, 0, 0) !important;
  backface-visibility: hidden !important;
  will-change: transform !important;
}

.swiper-slide img {
  image-rendering: auto !important; /* Ensures the image maintains quality */
  transform: translateZ(0) !important; /* Forces the browser to render in high quality */
}

.confetti-piece:nth-child(1) {
  left: 7%;
  -webkit-transform: rotate(-40deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 182ms;
  -webkit-animation-duration: 1116ms;
}
.confetti-piece:nth-child(2) {
  left: 14%;
  -webkit-transform: rotate(4deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 161ms;
  -webkit-animation-duration: 1076ms;
}
.confetti-piece:nth-child(3) {
  left: 21%;
  -webkit-transform: rotate(-51deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 481ms;
  -webkit-animation-duration: 1103ms;
}
.confetti-piece:nth-child(4) {
  left: 28%;
  -webkit-transform: rotate(61deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 334ms;
  -webkit-animation-duration: 708ms;
}
.confetti-piece:nth-child(5) {
  left: 35%;
  -webkit-transform: rotate(-52deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 302ms;
  -webkit-animation-duration: 776ms;
}
.confetti-piece:nth-child(6) {
  left: 42%;
  -webkit-transform: rotate(38deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 180ms;
  -webkit-animation-duration: 1168ms;
}
.confetti-piece:nth-child(7) {
  left: 49%;
  -webkit-transform: rotate(11deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 395ms;
  -webkit-animation-duration: 1200ms;
}
.confetti-piece:nth-child(8) {
  left: 56%;
  -webkit-transform: rotate(49deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 14ms;
  -webkit-animation-duration: 887ms;
}
.confetti-piece:nth-child(9) {
  left: 63%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 149ms;
  -webkit-animation-duration: 805ms;
}
.confetti-piece:nth-child(10) {
  left: 70%;
  -webkit-transform: rotate(10deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 351ms;
  -webkit-animation-duration: 1059ms;
}
.confetti-piece:nth-child(11) {
  left: 77%;
  -webkit-transform: rotate(4deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 307ms;
  -webkit-animation-duration: 1132ms;
}
.confetti-piece:nth-child(12) {
  left: 84%;
  -webkit-transform: rotate(42deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 464ms;
  -webkit-animation-duration: 776ms;
}
.confetti-piece:nth-child(13) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(14) {
  left: 71%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(15) {
  left: 81%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(16) {
  left: 41%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(17) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(18) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(19) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(17) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(20) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(21) {
  left: 91%;
  -webkit-transform: rotate(-72deg);
  -webkit-animation: makeItRain 1000ms infinite ease-out;
  -webkit-animation-delay: 429ms;
  -webkit-animation-duration: 818ms;
}
.confetti-piece:nth-child(odd) {
  background: #7431e8;
}
.confetti-piece:nth-child(even) {
  z-index: 1;
}
.confetti-piece:nth-child(4n) {
  width: 5px;
  height: 12px;
  -webkit-animation-duration: 2000ms;
}
.confetti-piece:nth-child(3n) {
  width: 3px;
  height: 10px;
  -webkit-animation-duration: 2500ms;
  -webkit-animation-delay: 1000ms;
}
.confetti-piece:nth-child(4n-7) {
  background: red;
}
@keyframes makeItRain {
  from {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  to {
    -webkit-transform: translateY(750px);
  }
}
